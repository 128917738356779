const authenticate = {
    computed:{
        pageTitle(){
            return this.Request && this.Request.VendorBusinessName?this.Request.VendorBusinessName:'';
           },
        },
   async mounted() {
        if (!this.canApproveCurrentWorkflowRequest) {
            this.$router.push({
                name: "error",
                params: {
                    message: "UnAuthorized"
                },
            });
        } else {
           await this.$store
                .dispatch("mediavendor/GetRequestWithTaskDetails", parseInt(this.id))
                .then((res) => {
                    this.Request = res.data;
                    this.$store
                        .dispatch("vendorValidation/GetVendorValidationDetails",{
                            id : this.id,
                            vendorType:"Media",
                        })
                        .then((res) => {
                            if(res) {
                                this.VendorValidationFiles = res.data.Files;
                            }
                        })
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403) {
                        this.$router.push({
                            name: "error",
                            params: {
                                message: "UnAuthorized"
                            },
                        });
                    }
                    else {
                        this.Error = true;
                        alert(err);
                    }
                });
        }
    },
};
export default authenticate;